<template>
  <div class="list_container">
    <div class="item card" v-for="(item, index) in list" :key="item.id"
         :class="{'grid_item': isRadio}">
      <el-checkbox v-if="isRadio" v-model="item.radio" @change="onCheckboxChange(item)"
                   :class="{'isSelectCheck':item.radio }"></el-checkbox>
      <div class="item_info_container">
        <div class="title" :class="{'title_isRead': read(item.id)}">
          <span class="item_index">
            {{index+1}}.
          </span>
          <img class="pdf_icon" v-if="item.qiniuUrl" src="@/assets/img/list_pdf_icon.png" alt="">
          <a v-if="$store.getters.userId" class="document_title" style="text-decoration:none;"
             :href="`https://www.infox-med.com/#/articleDetails?id=${item.id}`"
             @click="titleClick($event,item)">
            <span v-if="!item.isTranslate" class="document_title">{{item.titleEn}}</span>
            <span v-if="item.isTranslate" class="document_title">{{item.titleCn}}</span>
          </a>
          <span class="document_title hand" @click="$login" v-else>{{item.titleEn}}</span>
          <i class="iconfont icon-wenxianfanyi title_translate_icon hand"
             @click.stop="translate(item)"></i>
        </div>
        <div class="info_container">
          <p class="framer info_item">制定者：{{item.framer}}</p>
          <p class="source info_item">出处；{{item.provenance}}</p>
          <div class="info_item column">
            <span>科室：{{item.ejks}}</span>
            <span>发表时间：{{$initTime(item.docPublishTime)}}</span>
          </div>
        </div>
        <div class="docAbstract">
          <p v-if="!item.isTranslate" class="row_2">{{item.docAbstract}}</p>
          <p v-if="item.isTranslate && item.docAbstractZh" class="abstract_zh">
            {{item.docAbstractZh}}
          </p>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import { getMyRead, setMyRead } from '@/utils/storage.js'
import { blankPagePar } from '@/utils/index.js'
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    isRadio: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data () {
    return {
      readIds: []
    }
  },
  methods: {
    onCheckboxChange (item) {
      this.$emit('checkboxChange', item)
    },
    translate (item) {
      if (!this.$store.getters.userId) return this.$login()
      item.isTranslate = !item.isTranslate
    },
    read (id) {
      return this.readIds.includes(id)
    },
    toDetails (item) {
      if (!this.$store.getters.userId) return this.$login()
      let arr = JSON.parse(getMyRead() || '[]')
      arr.unshift({
        id: item.id
      })
      arr = arr.map(items => {
        return JSON.stringify(items)
      })
      arr = [...new Set(arr)]
      arr = arr.map(it => {
        return JSON.parse(it)
      })
      setMyRead(arr)
      this.readIds.push(item.id)
      blankPagePar('guideDetails', {
        id: item.id
      })
    },
    titleClick (event, item) {
      const e = event || window.event
      e.preventDefault()
      e.returnValue = false
      e.preventDefault ? e.preventDefault() : e.returnValue = false
      this.toDetails(item)
    }
  },
  created () {
    this.readIds = JSON.parse(getMyRead() || '[]').map(item => item.id)
  }
}
</script>

<style lang="less" scoped>
.list_container {
  display: grid;
  row-gap: 10px;
  .item {
    min-height: 30px;
    padding: 15px;
    transition: all 0.3s;
    &:hover {
      box-shadow: 6px 6px 7px 0 rgba(204, 204, 204, 0.5);
    }
  }
  .grid_item {
    display: grid;
    grid-template-columns: 24px 1fr;
    align-items: start;
    justify-content: space-between;
  }
  // 复选框
  /deep/ .isSelectCheck {
    .el-checkbox__inner {
      animation: isSelectCheckAnimation 0.3s ease;
    }
  }
  @keyframes isSelectCheckAnimation {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(10deg);
    }
    50% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-10deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  .title {
    color: #4b639f;
    .item_index {
      font-size: 18px;
      // margin-right: 5px;
    }
  }
  .document_title {
    font-size: 18px;
    line-height: 1.5em;
    color: #4b639f;
    &:hover {
      text-decoration: underline;
      text-underline-offset: 5px;
    }
  }
  .pdf_icon {
    position: relative;
    top: -3px;
    width: 16px;
    margin: 0 5px;
  }
  .title_translate_icon {
    font-size: 20px;
    margin-right: 0;
    margin-left: 10px;
  }
  .title_isRead {
    color: #8132b5 !important;
    .document_title {
      color: #8132b5 !important;
    }
  }
  .info_container {
    margin-top: 8px;
    .info_item {
      font-size: 14px;
      color: #666;
      line-height: 1.5em;
    }
    .column {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      justify-content: space-between;
    }
  }
  .docAbstract {
    margin-top: 3px;
    color: #666;
    line-height: 1.3em;
  }
  .abstract_zh {
    background-color: #f5f7fb;
    padding: 10px;
    border-radius: 4px;
    line-height: 1.5em;
  }
}
</style>
