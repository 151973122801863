import { setApi } from './index'
const paths = {
  guideList: '/guideline/getList',
  searchGuideList: '/guideline/search',
  guideKsList: '/guideline/getKeshi',
  guideDetails: '/guideline/getGuidelineById'
}

const guide = setApi(paths)
export default guide
