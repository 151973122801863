<template>
  <div class="library" ref="library">
    <div class="library-box">
      <div class="library-item">
        <h3>>>您正在访问指南</h3>
        <div class="tab" :class="{'hide_tab': isShowSearch}">
          <div class="title">学科:</div>
          <el-tabs v-model="categoryName" @tab-click="tabChange">
            <el-tab-pane label="全部" name="0"></el-tab-pane>
            <el-tab-pane v-for="item in guideCategoryList" :key="item.id" :label="item.ejks"
                         :name="item.ejks"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="search_container" :class="{'hide_tab':!isShowSearch}">
          <el-input v-model="keywords" size="small" placeholder="请输入指南标题关键词"></el-input>
          <span class="search_btn" @click="search">搜索</span>
        </div>
        <div class=" warn_container mb20">
          <div class="search_box">
            <span class="open_search" @click="openSearch">
              <i v-if="!isShowSearch" class="el-icon-search"></i>
              {{isShowSearch?'取消搜索':'指南搜索'}}</span>
          </div>
          <div class="download_btn hand" ref="openDialogBtn" @click="openDownload">下载 Info X Med
            APP，随时阅读>></div>
        </div>
        <!-- <article-list :honDocuList="list" /> -->
        <List :list="list" />
        <div class="bottom-btn" v-if="!isEmpty">
          <span @click="seeMore" :class="{'more_btn':isMore}">{{isMore?'查看更多>>':'暂无更多'}}</span>
        </div>
        <Empty v-else />
      </div>
    </div>
    <DownloadApp :show.sync="showDownloadApp" :options="dialogOptions">
      <template #title>
        <div class="download_btn fs16 mb10">
          下载 Info X Med APP，随时阅读
        </div>
      </template>
      <template #foot>
        <div class="foot_box fs14">
          扫码下载APP
        </div>
      </template>

    </DownloadApp>
  </div>
</template>
<script>
import DownloadApp from '@c/downloadAppDlalog/index.vue'
import List from './components/list.vue'
import guide from '@/api/guideline.js'
export default {
  components: {
    DownloadApp,
    List
  },
  data () {
    return {
      isEmpty: false,
      categoryName: '0',
      guideCategoryList: [],
      list: [], // 文献列表
      keywords: '',
      keywordsVal: '',
      isSearch: false,
      isShowSearch: false,
      // 分页参数
      pagesObj: {
        pageNum: 1,
        pageSize: 10
      },
      isMore: false,
      dialogOptions: {},
      showDownloadApp: false
    }
  },
  methods: {
    search () {
      if (!this.keywords) return
      if (this.keywords === this.keywordsVal) return
      this.keywordsVal = this.keywords
      document.documentElement.scrollTop = 0
      this.pagesObj.pageNum = 1
      this.list = []
      this.isSearch = true
      this.getList()
    },
    openSearch () {
      this.isShowSearch = !this.isShowSearch
      if (!this.isShowSearch) {
        this.keywords = ''
        this.isSearch = false
        this.pagesObj.pageNum = 1
        this.list = []
        this.getList()
      }
    },
    async getGuideGategoryList () {
      const res = await guide.guideKsList()
      this.guideCategoryList = res.data
    },
    setdialogOptions () {
      const boxInfo = this.$refs?.openDialogBtn?.getBoundingClientRect()
      this.dialogOptions = {
        x: boxInfo.x + 111,
        y: boxInfo.y + 10
      }
    },
    openDownload () {
      this.setdialogOptions()
      this.$nextTick(() => (this.showDownloadApp = true))
    },
    // 查看更多
    seeMore () {
      if (!this.isMore) return
      this.pagesObj.pageNum++
      if (this.keywords !== this.keywordsVal) {
        this.keywords = this.keywordsVal
      }
      this.getList()
    },
    async getList () {
      const body = { ...this.pagesObj }
      if (this.categoryName !== '0' && !this.isSearch) {
        body.ejks = this.categoryName
      }
      if (this.isSearch) {
        body.keywords = this.keywordsVal
      }
      const res = await guide[this.isSearch ? 'searchGuideList' : 'guideList'](body)
      this.isMore = res.data.length === this.pagesObj.pageSize
      const data = res.data.map(item => ({ ...item, isTranslate: false }))
      this.list = [...this.list, ...data]
      this.isEmpty = this.list.length === 0
    },
    tabChange () {
      this.pagesObj.pageNum = 1
      this.list = []
      this.getList()
      if (document.documentElement.scrollTop > 250) {
        document.documentElement.scrollTop = 250
      }
    }
  },
  created () {
    this.getGuideGategoryList()
    this.getList()
  }
}
</script>
<style lang="less" scoped>
.library-box {
  min-height: 100vh;
  background-color: #f5f7fb;
  .library-item {
    width: 1200px;
    padding: 10px 0px 20px;
    margin: 0 auto;
    h3 {
      font-size: 14px;
      letter-spacing: 2px;
      margin-bottom: 10px;
      color: #4b639f;
    }
    ul {
      display: flex;
      align-items: center;
      padding: 0 0 20px;
      border-bottom: 1px solid #ccc;
      font-size: 18px;
      & :first-child {
        font-weight: 700;
      }
      li {
        padding: 5px 10px;
        margin-right: 30px;
        border-radius: 5px;
      }
      .isli {
        color: #fff;
        background-color: #4b639f;
      }
    }
    .tab {
      position: -webkit-sticky;
      position: sticky;
      top: 52px;
      z-index: 100;
      width: 1200px;
      height: 50px;
      display: flex;
      align-items: center;
      padding: 10px 0 10px 0px;
      border-bottom: 1px solid #e8e8e8;
      background-color: #fff;
      transition: all 0.3s;
      .title {
        font-size: 18px;
        font-weight: 700;
        margin: 0 20px 0 20px;
      }
      /deep/ .el-tabs {
        width: calc(100% - 95px);
        .el-icon-arrow-left:before,
        .el-icon-arrow-right::before {
          position: relative;
          top: -1px;
          padding: 2px;
          border-radius: 50%;
          color: #4b639f;
          font-size: 16px;
          font-weight: 700;
        }
        .el-icon-arrow-left:before {
          content: '\e6dd';
        }
        .el-icon-arrow-right:before {
          content: '\e6dc';
        }
      }
      /deep/.el-tabs__item {
        font-size: 16px;
        padding: 0 10px;
        &:hover {
          color: #4b639f;
        }
      }
      /deep/ .el-tabs__header {
        margin: 0;
      }
      /deep/.el-tabs__nav-wrap::after {
        width: 0;
        height: 0;
      }
      /deep/ .is-active {
        font-weight: 700;
        color: #4b639f;
      }
      /deep/.el-tabs__active-bar {
        background-color: #4b639f;
      }
    }

    .search_container {
      position: sticky;
      top: 52px;
      z-index: 100;
      display: grid;
      grid-template-columns: 1fr auto auto;
      align-items: center;
      column-gap: 20px;
      height: 50px;
      border-bottom: 1px solid #e8e8e8;
      background-color: #fff;
      padding: 0 100px;
      transition: all 0.3s;
      .search_btn {
        padding: 3px 15px;
        color: #4b639f;
        font-size: 14px;
        font-weight: 600;
        border: 1px solid #4b639f;
        cursor: pointer;
        &:hover {
          color: #fff;
          background-color: #4b639f;
        }
      }
    }
    .hide_tab {
      height: 0;
      padding: 0;
      overflow: hidden;
      border-bottom: 0;
    }
    .warn_container {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      justify-content: space-between;
      column-gap: 30px;
      height: 40px;
      background-color: #fff;
      font-size: 14px;
      padding: 0 20px;
      .download_btn {
        color: #ff832a;
      }
      .search_box {
        .open_search {
          padding: 5px 15px;
          color: #4b639f;
          cursor: pointer;
          border-radius: 3px;
          &:hover {
            color: #fff;
            background-color: #4b639f;
          }
        }
      }
    }
    .bottom-btn {
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 16px;
      color: #666;
    }
    .more_btn {
      color: #4b639f;
      cursor: pointer;
      span:hover {
        font-weight: 700;
      }
    }
  }
}
.download_btn {
  color: #ff832a;
}
.foot_box {
  letter-spacing: 5px;
}
</style>
